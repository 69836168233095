@keyframes moveDown {
    from {
        transform: none;
        opacity: 0;
    }
    to {
        transform: translateY(20px);
        opacity: 1;
    }
}

.animated {
    animation-duration: 0.5s;
    animation-delay: 1s;
    animation-fill-mode: both;
    animation-iteration-count: 3;
    animation-name: moveDown;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.fade {
    animation: fadeOut 1s;
    animation-delay: 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
