.gallery {
    display: grid;
    grid-gap: 0.5rem;
  
    @include media-breakpoint-only(xs) {
      grid-template-columns: repeat(2, 1fr);
      -ms-grid-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-only(sm) {
      grid-template-columns: repeat(3, 1fr);
      -ms-grid-columns: repeat(3, 1fr);
    }
    @include media-breakpoint-only(md) {
      grid-template-columns: repeat(4, 1fr);
      -ms-grid-columns: repeat(4, 1fr);
    }
    @include media-breakpoint-only(lg) {
      grid-template-columns: repeat(5, 1fr);
      -ms-grid-columns: repeat(5, 1fr);
    }
    @include media-breakpoint-only(xl) {
      grid-template-columns: repeat(6, 1fr);
      -ms-grid-columns: repeat(6, 1fr);
    }
}

.img-fluid {
    max-width: 100%;
    height: auto;
}
