body {
    margin: 0px;
}

header {
    height: 100vh;
    background-color: #24292e;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    @media only screen and (max-height: 30em) {
		height: 100vw;
	}
}

.top-buffer {
    margin-top: 3rem;
}

.container {
    max-width: 80rem;
}

.text--center {
    text-align: center;
}

.lead {
    color: #fefefe;

    h1 {
        font-size: 3.2rem;
    }
}
