.icon {
    width: 18px;
    height: 18px;
    vertical-align: sub;
    fill: currentColor;

    &.icon--arrow-down-circle {
        width: 42px;
        height: 42px;
    }
}
